import {mapState} from 'vuex'
import PermissionTable from './components/PermissionTable'
import {isEqual, snakeCase} from '@/utils/helper'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'

export default {
    inject: ['toast'],
    components: {
        PermissionTable,
        ConfirmDialog,
    },
    data() {
        return {
            mountedComponent: false,
            loading: false,
            origin: {},
            permissions: {
                general: [],
                sub: [],
            },
        }
    },
    computed: {
        ...mapState({
            permissionsKey: (state) => state.permissionGroup.permissionsKey,
            configTypes: (state) => state.permissionGroup.configTypes,
            formKeys: (state) => state.permissionGroup.formKeys,
        }),
    },
    mounted() {
        this.$nextTick(() => {
            this.mountedComponent = true
        })
    },
    created() {
        this.$nextTick(() => this.fetchData())
    },
    methods: {
        fetchData() {
            this.loading = true
            this.$http.setting
                .fetchPermissionGroup()
                .then((res) => {
                    let data = res.data.data
                    this.permissions = this.transformData(data['roles_to_permissions'])
                    this.origin = JSON.parse(JSON.stringify(this.permissions))
                })
                .catch(() => this.toast('warning', 'Error fetch data'))
                .finally(() => (this.loading = false))
        },
        transformData(data) {
            let res = {general: [], sub: []}
            data.map((item) => {
                item['permissions'] = this.mapPermissions(item['permissions'])
                item['role_name'].includes('general')
                    ? res.general.push(item)
                    : res.sub.push(item)
            })

            return res
        },
        mapPermissions(obj = {}) {
            let permission = []
            for (let key in obj) {
                let item = {
                    site_feature: key,
                    type: snakeCase(key),
                    none: false,
                }
                for (let i = 0; i < obj[key].length; i++) {
                    let trans = this.permissionsKey[obj[key][i]['permission_name']]
                    obj[key][i]['disabled'] = true;
                    item[trans] = obj[key][i] || {}
                }
                permission.push(item)
            }
            this.setConfigDisabled(permission)
            return permission
        },
        setConfigDisabled(arr = []) {
            for (let i = 0; i < arr.length; i++) {
                let type = arr[i]['type']
                for (let key in arr[i]) {
                    if (this.formKeys.includes(key)) {
                        this.configTypes[type][key]['disabled'].forEach((item) => {
                            arr[i][item]['disabled'] = true;
                        })
                    }
                }
            }
        },
        makeData() {
            let data = []
            for (let k in this.permissions) {
                this.permissions[k].map((role) => {
                    let item = {role_id: role.role_id, permissions_id: []}
                    role['permissions'].map((permissions) => {
                        for (let key in permissions) {
                            if (this.formKeys.includes(key) && permissions[key]['checked']) {
                                item.permissions_id.push(permissions[key]['permission_id'])
                            }
                        }
                    })
                    data.push(item)
                })
            }
            return {roles: data}
        },
        handleSave() {
            this.loading = true
            this.$http.setting
                .savePermissionGroup(this.makeData())
                .then((response) => {
                    this.toast('info', response.data.message)
                })
                .catch(({response}) => {
                    if (response.status === 422) {
                        for (let key in response.data.errors) {
                            this.toast('warning', response.data.errors[key][0] || 'Error')
                        }
                    } else {
                        this.toast('warning', 'Error')
                    }
                })
                .finally(() => (this.loading = false))
        },
        async handleCancel() {
            if (!isEqual(this.permissions, this.origin)) {
                await this.$refs.confirmDialog
                    .confirm({
                        text: 'You have unsaved changes.',
                        info: 'Are you sure, you want to cancel them?',
                        reverse: true,
                    })
                    .then((res) => {
                        if (res) this.fetchData()
                    })
            } else {
                this.fetchData()
            }
        },
    },
}
